import React, { Component } from 'react'
import { Link } from 'gatsby'
import '../styles/layout.css'

import { rhythm } from './../utils/typography'
import contactData from './../data/contact'
import Resume from './../assets/Petrey-McRae-RESUME.pdf'

export class Navbar extends Component {
  render() {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: rhythm(24),
            maxWidth: 400,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Link to={'/'} className="nav-link">
            Home
          </Link>
          {/* <Link to={'/projects'} className="nav-link">
            Projects
          </Link> */}
          <Link to={'/experience'} className="nav-link">
            Experience
          </Link>
          <a href={Resume} target="_new" className="nav-link">
            Resume
          </a>
          {/* <Link to={'/contact'} className="nav-link">
            Contact
          </Link> */}
          {/* <Link to={'/blog'}>Blog</Link> */}
        </div>
      </div>
    )
  }
}

export default Navbar
