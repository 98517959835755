import React from 'react'

const Header = () => {
  return (
    <div
      style={{
        textAlign: 'center',
        // borderBottom: `solid 1px black`,
      }}
    >
      {/* <h2>Site Title</h2> */}
    </div>
  )
}

export default Header
