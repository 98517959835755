import React, { Component } from 'react'
import { FaGithub, FaLinkedinIn, FaTwitter } from 'react-icons/fa'

const styles = {
  rootContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    textTransform: 'uppercase',
    letterSpacing: 2,
    color: 'gray',
    paddingTop: 15,
    marginTop: 15,
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '3',
    marginRight: '3',
  },
}

export class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <div style={styles.rootContainer}>
          McRae Petrey
          <a href="https://github.com/max-deathray" target="_new">
            <FaGithub style={styles.icons} className="footer" />
          </a>{' '}
          <a
            href="https://www.linkedin.com/in/mcraepetrey/"
            target="_new"
            className="footer"
          >
            <FaLinkedinIn style={styles.icons} />
          </a>{' '}
          <a
            href="https://www.twitter.com/mpetrey"
            target="_new"
            className="footer"
          >
            <FaTwitter style={styles.icons} />
          </a>{' '}
          {/* built with&nbsp;
          <a href="https://gatsbyjs.org" target="_blank">
            GatsbyJS
          </a> */}
        </div>
      </React.Fragment>
    )
  }
}

export default Footer
