// import Typography from 'typography'
// import Github from 'typography-theme-github'

// const typography = new Typography(Github)

// export default typography
// export const rhythm = typography.rhythm
// export const scale = typography.scale

import Typography from 'typography'
import altonTheme from 'typography-theme-alton'

const typography = new Typography(altonTheme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export const { scale, rhythm, options } = typography
export default typography
